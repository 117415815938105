import { useMemo } from 'react'
import { SanityAsset } from '@odainc/sanity-types'

export const useSanityImage = (imageAsset?: SanityAsset) => {
  const { imgWidth, imgHeight, aspectratio } = useMemo(() => {
    let [width, height] = imageAsset
      ? imageAsset._ref
          .replace(/.*?-(\d+x\d+)-[a-z]+$/, '$1')
          .split('x')
          .map((num) => parseInt(num, 10))
      : [1, 1]

    return {
      imgWidth: width,
      imgHeight: height,
      aspectratio: width && height ? width / height : 1,
    }
  }, [imageAsset])

  return {
    imgWidth,
    imgHeight,
    aspectratio,
  }
}
