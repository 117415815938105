import React, { FC, useCallback, useRef, useState } from 'react'
import { SanityAsset } from '@odainc/sanity-types'

import FluidImage from './base/FluidImage'
import { useSanityImage } from '../lib/SanityUtils'

export const getSanitySrcSet = (url: string, width: number) => {
  const widths = [0.5, 0.75, 1, 1.5, 2, 3]
  return widths
    .map((w) => {
      const size = Math.round(w * width)
      return `${url}?w=${size} ${size}w`
    })
    .join(', ')
}

const SanityFluidImage: FC<{
  url: string
  width: number
  aspectRatio?: number
  sizes: string
  className?: string
  transparent?: boolean
  imageAsset?: SanityAsset
}> = ({
  url,
  width,
  aspectRatio,
  sizes,
  className,
  transparent,
  imageAsset,
}) => {
  const { imgWidth, imgHeight, aspectratio } = useSanityImage(imageAsset)

  return (
    <FluidImage
      image={{
        fluid: {
          aspectRatio: aspectRatio ? aspectRatio : aspectratio,
          src: `${url}?w=${width}`,
          srcSet: getSanitySrcSet(url, width),
        },
      }}
      transparent={transparent}
      sizes={sizes}
      className={className}
    />
  )
}

export default SanityFluidImage
